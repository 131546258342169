/* eslint-disable react/jsx-one-expression-per-line */
import {
  NumberInput,
  NumberInputField,
  Heading,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  FormControl,
  FormLabel,
  Link,
  Box,
  Text,
  Flex,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { round } from 'lodash';
import { useState } from 'react';

// These are variables for multiplying
// against our input number that has
// been subtracted
const mulHunK = 0.00527;
const mulMill = 0.00433;
const mulFiveMill = 0.00357;
const mulFifteenMill = 0.00254;
const mulTwenFiveMill = 0.00152;
const mulFiftyMill = 0.00138;
const mulHunMillPlus = 0.00124;

// These are variables for adding
// to our input number that has
// been multiplied
const addHunK = 832;
const addMill = 5575;
const addFiveMill = 22895;
const addFifteenMill = 58595;
const addTwenFiveMill = 83995;
const addFiftyMill = 121995;
const addHunMillPlus = 190995;

// I wish there was a better way to do this...
// This data is needed to set premium rates
// per $500 I couldn't find a common pattern
// to make this dynamic.
const policyToPremium = {
  25000.0: 328.0,
  25500.0: 331.0,
  26000.0: 335.0,
  26500.0: 338.0,
  27000.0: 340.0,
  27500.0: 343.0,
  28000.0: 347.0,
  28500.0: 350.0,
  29000.0: 355.0,
  29500.0: 358.0,
  30000.0: 361.0,
  30500.0: 364.0,
  31000.0: 368.0,
  31500.0: 371.0,
  32000.0: 374.0,
  32500.0: 378.0,
  33000.0: 381.0,
  33500.0: 385.0,
  34000.0: 388.0,
  34500.0: 392.0,
  35000.0: 395.0,
  35500.0: 398.0,
  36000.0: 401.0,
  36500.0: 405.0,
  37000.0: 408.0,
  37500.0: 412.0,
  38000.0: 416.0,
  38500.0: 419.0,
  39000.0: 421.0,
  39500.0: 425.0,
  40000.0: 428.0,
  40500.0: 433.0,
  41000.0: 435.0,
  41500.0: 439.0,
  42000.0: 442.0,
  42500.0: 446.0,
  43000.0: 448.0,
  43500.0: 452.0,
  44000.0: 456.0,
  44500.0: 459.0,
  45000.0: 463.0,
  45500.0: 466.0,
  46000.0: 469.0,
  46500.0: 473.0,
  47000.0: 475.0,
  47500.0: 478.0,
  48000.0: 483.0,
  48500.0: 487.0,
  49000.0: 490.0,
  49500.0: 493.0,
  50000.0: 496.0,
  50500.0: 499.0,
  51000.0: 501.0,
  51500.0: 505.0,
  52000.0: 510.0,
  52500.0: 514.0,
  53000.0: 516.0,
  53500.0: 520.0,
  54000.0: 523.0,
  54500.0: 526.0,
  55000.0: 529.0,
  55500.0: 532.0,
  56000.0: 537.0,
  56500.0: 540.0,
  57000.0: 543.0,
  57500.0: 547.0,
  58000.0: 551.0,
  58500.0: 553.0,
  59000.0: 556.0,
  59500.0: 560.0,
  60000.0: 564.0,
  60500.0: 568.0,
  61000.0: 571.0,
  61500.0: 573.0,
  62000.0: 577.0,
  62500.0: 581.0,
  63000.0: 583.0,
  63500.0: 587.0,
  64000.0: 591.0,
  64500.0: 594.0,
  65000.0: 597.0,
  65500.0: 600.0,
  66000.0: 604.0,
  66500.0: 609.0,
  67000.0: 612.0,
  67500.0: 613.0,
  68000.0: 617.0,
  68500.0: 621.0,
  69000.0: 624.0,
  69500.0: 627.0,
  70000.0: 631.0,
  70500.0: 635.0,
  71000.0: 639.0,
  71500.0: 641.0,
  72000.0: 644.0,
  72500.0: 648.0,
  73000.0: 651.0,
  73500.0: 654.0,
  74000.0: 658.0,
  74500.0: 662.0,
  75000.0: 666.0,
  75500.0: 668.0,
  76000.0: 671.0,
  76500.0: 674.0,
  77000.0: 678.0,
  77500.0: 681.0,
  78000.0: 685.0,
  78500.0: 689.0,
  79000.0: 693.0,
  79500.0: 694.0,
  80000.0: 698.0,
  80500.0: 702.0,
  81000.0: 706.0,
  81500.0: 708.0,
  82000.0: 711.0,
  82500.0: 716.0,
  83000.0: 720.0,
  83500.0: 722.0,
  84000.0: 725.0,
  84500.0: 729.0,
  85000.0: 732.0,
  85500.0: 735.0,
  86000.0: 738.0,
  86500.0: 743.0,
  87000.0: 747.0,
  87500.0: 749.0,
  88000.0: 752.0,
  88500.0: 756.0,
  89000.0: 760.0,
  89500.0: 762.0,
  90000.0: 765.0,
  90500.0: 769.0,
  91000.0: 773.0,
  91500.0: 777.0,
  92000.0: 779.0,
  92500.0: 783.0,
  93000.0: 786.0,
  93500.0: 790.0,
  94000.0: 791.0,
  94500.0: 796.0,
  95000.0: 801.0,
  95500.0: 804.0,
  96000.0: 805.0,
  96500.0: 809.0,
  97000.0: 813.0,
  97500.0: 817.0,
  98000.0: 820.0,
  98500.0: 824.0,
  99000.0: 827.0,
  99500.0: 830.0,
  100000.0: 832.0,
};

function policyOverX(Amount) {
  if (Amount > 100000 && Amount <= 1000000) {
    return (Amount - 100000) * mulHunK + addHunK;
  }
  if (Amount > 1000000 && Amount <= 5000000) {
    return (Amount - 1000000) * mulMill + addMill;
  }
  if (Amount > 500000 && Amount <= 15000000) {
    return (Amount - 5000000) * mulFiveMill + addFiveMill;
  }
  if (Amount > 1500000 && Amount <= 25000000) {
    return (Amount - 15000000) * mulFifteenMill + addFifteenMill;
  }
  if (Amount > 2500000 && Amount <= 50000000) {
    return (Amount - 25000000) * mulTwenFiveMill + addTwenFiveMill;
  }
  if (Amount > 5000000 && Amount <= 100000000) {
    return (Amount - 50000000) * mulFiftyMill + addFiftyMill;
  }
  if (Amount > 100000000 && Amount < 1000000000) {
    return (Amount - 100000000) * mulHunMillPlus + addHunMillPlus;
  }
  if (Amount > 1000000000) return NaN;
}

const PremiumRates = () => {
  const [calc, setCalc] = useState(328);
  const [numValue, setNumValue] = useState(25000);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    reValidateMode: 'onChange',
  });

  const handleValueChange = (numValue) => {
    setNumValue(numValue);

    if (premiumCalculator.value > 100000) {
      const c = policyOverX(premiumCalculator.value);
      setCalc(round(c));
    } else {
      premiumCalculator.value = numValue;
      setNumValue(premiumCalculator.value);
      setCalc(policyToPremium[numValue]);
    }
  };

  return (
    <Box mt={10}>
      <form>
        <FormControl maxWidth={{ base: '90vw', md: '45vw' }}>
          <Heading variant="sec" fontSize="3xl">
            Texas Title Insurance Basic Premium&nbsp;Rates
          </Heading>
          <Flex flexDir="column" mb={6}>
            <FormLabel>Policy Amount</FormLabel>
            <Box mb={4} maxWidth="600px" mx={2}>
              <Flex justifyContent="space-between" mb="-20px">
                <Text fontSize="xs">$25,000</Text>
                <Text fontSize="xs">$100,000</Text>
              </Flex>
              <Slider
                focusThumbOnChange={false}
                min={25000}
                max={100000}
                step={500}
                flex="1"
                value={numValue}
                onChange={handleValueChange}
                colorScheme="gray"
              >
                <SliderTrack>
                  <SliderFilledTrack />
                </SliderTrack>
                <SliderThumb
                  fontSize="xs"
                  backgroundColor="brand.three"
                />
              </Slider>
            </Box>
            <Text color="red.300" mt="0">
              Please type in any policy amount over $100,000 in the box below.
            </Text>
            <NumberInput
              maxWidth="50%"
              name="premiumCalculator"
              {...register('premiumCalculator')}
              id="premiumCalculator"
              keepWithinRange
              placeholder="Input the policy amount."
              defaultValue={25000}
              min={25000}
              max={1000000000}
              step={500}
              size="lg"
              value={numValue}
              onChange={handleValueChange}
            >
              <NumberInputField />
            </NumberInput>
            {errors.premiumCalculator ? (
              <span>{errors.premiumCalculator.message}</span>
            ) : null}
          </Flex>
          {/* <Button onClick={handleSubmit(onSubmit)}>Submit</Button> */}
          <Text maxWidth="80vw" fontSize="md">
            The basic premium cost for a ${numValue} policy is: $
            {calc}
          </Text>
          <Text color="blue.300" fontSize="xs" mt="-5">
            <Link
              target="_blank"
              href="https://www.tdi.texas.gov/title/titlerates2019.html"
            >
              Source
            </Link>
          </Text>
        </FormControl>
      </form>
    </Box>
  );
};

export default PremiumRates;
