export const Fonts = {
  // heading: "'Inter', sans-serif",
  heading: "'Literata', serif;",
  body: "'Roboto', sans-serif;",
};

// Heading
export const Headings = {
  baseStyle: {
    fontWeight: 900,
    color: '#3c474e',
    marginBottom: '6',
    lineHeight: '150%',
    letterSpacing: '0.1rem',
  },
  // Styles for the size variations
  sizes: {
    '5xl': {
      fontSize: ['4xl', '7xl', '8xl'],
      letterSpacing: '0.2rem',
      lineHeight: '140%',
    },
    '4xl': {
      fontSize: ['4xl', null, '7xl'],
      lineHeight: '140%',
    },
    '3xl': {
      fontSize: ['4xl', null, '6xl'],
      lineHeight: '140%',
    },
    '2xl': {
      lineHeight: '140%',
    },
    xl: {
      lineHeight: '140%',
    },
    lg: {
      lineHeight: '140%',
    },
  },
  // Styles for the visual style variations
  variants: {
    sec: {
      lineHeight: 1.5,
      fontFamily: "'Roboto', sans-serif;",
      color: '#505b62',
    },
    tri: {
      fontFamily: '"Noto Sans, sans-serif',
    },
  },
  // The default `size` or `variant` values
  defaultProps: {},
};

export const Texts = {
  baseStyle: {
    fontFamily: "'Noto Sans', sans-serif",
    color: 'gray.600',
    fontWeight: 500,
    letterSpacing: '0.1rem',
    lineHeight: 1.5,
    marginBottom: '6',
  },
  variants: {
    sec: {
      color: 'gray.700',
    },
    tri: {
      color: 'gray.400',
    },
  },
};
