export const CommercialCashPurchaseIndividual = {
  Name: 'Commercial Property Cash Purchase - by Individual',
  BuyerForms: [
    'Waiver of Inspection   (included)',
    'Recording Fees   (included)',
    'Closing Fees   (included)',
    'Overnight/Wire Fees    (if applicable)',
  ],
  BuyerPrice: '$240.00',
  SellerForms: [
    'Warranty Deed (Special or General)   (included)',
    'Affidavit of Debts and Liens   (no charge)',
    'IRS 1099   (no charge)',
    'T-47 (Survey Affidavit)    (no charge)',
    'Tax Certificate  (included)',
    'Closing Fee    (included)',
    'Overnight/Wire Fee   (if applicable)',
  ],
  SellerPrice: '425.00',
};
