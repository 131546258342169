import { Flex } from '@chakra-ui/react';

const NavBarContainer = ({ children, ...rest }) => {
  return (
    <Flex
      as="nav"
      alignItems="center"
      justifyContent="space-between"
      wrap="wrap"
      {...rest}
    >
      {children}
    </Flex>
  );
};

export default NavBarContainer;
