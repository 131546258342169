import { Box, Flex, Heading } from '@chakra-ui/react';
import Sunset from '../../../../Assets/Images/landscape03.jpg';
import { Card } from '../../../_index';

const HeroContent = () => (
  <Card
    padding={10}
  >
    <Flex
      align={"flex-end"}
    >
      <Heading
        as="h1"
        size="2xl"
        mb={10}
        color={ {base: "white", md:"brand.five" } }
      >
        Cowboy Country Title, LLC
      </Heading>
    </Flex>

    <Heading
      as="h3"
      variant="sec"
      size="lg"
      ml="10px"
      color={ {base: "white", md:"brand.five" } }
    >
      Fast, Honest, Friendly
    </Heading>

  </Card>
);

const AltBackground = () => {
  const style = {
    height: {base: "200px", md: "auto"},
    width: "100%",
    bottom:"0px",
    backgroundImage: `url(${Sunset})`,
    backgroundPosition: "center top",
    display: "block",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  };
  return (
    <>
      <Box
        position="absolute"
        zIndex="-2"
        display="flex"
        top="0"
        style={style}
      />
    </>
  );
};

const HomeHero = () => (
  <Flex
    flexWrap="wrap"
    justifyContent="center"
  >
    <AltBackground />
    <HeroContent />
  </Flex>
);

export default HomeHero;
