export const RefinanceByBusiness = {
  Name: 'Refinance of Existing Loan by Business Entity',
  BuyerForms: [
    'Real Estate Lien Note',
    'Deed of Trust',
    'Environmental Addendum',
    'Adjustable Rate Loan Rider',
    'Disclaimer of Oral Agreements',
    'Guaranty Agreement',
    'Certificate of Resolutions',
    'Business Purpose Statement',
    'Assignment of Rent, Income and Receipts',
    'Certificate of Account Status from Secretary of State',
    'Certificate of Formation',
    'Company Agreement\\Bylaws',
    'Waiver of Inspection',
    'Recording Fee',
    'Closing Fee',
    'Overnight/Wire Fee',
  ],
  BuyerPrice: '$1475.00',
  SellerForms: [
    // 'Warranty Deed (Special or General)',
    // 'Affidavit of Debts and Liens',
    // 'IRS 1099',
    // 'T-47 (Survey Affidavit)',
    // 'Tax Certificate',
    // 'Closing Fee',
    // 'Overnight/Wire Fee',
  ],
  SellerPrice: 'N/A',
};
