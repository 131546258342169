import { Box,   CloseButton} from '@chakra-ui/react'
import { HamburgerIcon } from '@chakra-ui/icons';

const MenuToggle = ({ toggle, isOpen }) => {
  return (
    <Box display={{ base: 'block', lg: 'none' }} onClick={toggle}>
      {isOpen ? <CloseButton size="lg" /> : <HamburgerIcon boxSize="2rem" />}
    </Box>
  );
};

export default MenuToggle