import {
  Box,
  Container,
  Heading,
  Text,
  Image,
} from '@chakra-ui/react';
import React from 'react';
import WindMill02 from '../../../Assets/Images/evi-t-fQ9X2rPEwq8-unsplash.jpg';
import {
  AnimatedFadeUpBox,
  FullBleedContentImageSection,
} from '../../_index';

const OurApproach = () => (
  <Box
    backgroundColor="brand.two"
    py={10}
    pr={4}
    width={{ base: '100%', md: '60%' }}
    minHeight="500px"
  >
    <AnimatedFadeUpBox showOffset="300">
      <Heading color="brand.five" as="h3" size="2xl" mb={10}>
        Our customers matter!
      </Heading>

      <Text color="white" fontSize="2xl" className="readable" mb={6}>
      Throughout the closing process, we seek to maintain communication
      by utilizing the latest technology endorsed by the title insurance
      industry, which provides step by step updates to all parties in
      the transaction.
      </Text>
    </AnimatedFadeUpBox>
  </Box>
);

const SectionA = () => {
  return (
    <FullBleedContentImageSection
      backgroundColor="brand.two"
      contentBlock={<OurApproach />}
      bleedBlock={
        <Box width={{ base: '800px', md: '900px', lg: '800px' }}>
          <Image
            transform={{ base: 'scaleX(1)', md: 'scaleX(-1)' }}
            src={WindMill02}
            width="100%"
            height="100%"
            alt="windmill-two"
          />
        </Box>
      }
    />
  );
};

export default SectionA;
