import {
  Image,
  Box,
  Container,
  Heading,
  Flex,
  Text,
  Popover,
  PopoverTrigger,
  PopoverContent,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Button,
  Portal,
  UnorderedList,
  ListItem
} from '@chakra-ui/react';
import { Link as AppLink } from 'react-router-dom';
import HomeHeroSection from './HomeHeroSection';
import SectionA from './SectionA';
import SectionB from './SectionB';
import WelcomeSection from './WelcomeSection';
import { ReactComponent as Hill2 } from '../../../Assets/illustration/hill-xl-2.svg';

import TLTAMember from '../../../Assets/Images/TLTAMember.png';
import OldRepublic from '../../../Assets/Images/old-republic-title.png';
import { SheriffBadgeOne } from '../../../Assets/illustration/cowboy';
import { AnimatedFadeUpBox } from '../../_index';

const WhoWeWorkedWith = () => (
  <>
    <Heading
      color="brand.five"
      as="h3"
      size="2xl"
      className="readable"
      textAlign="center"
      mx="auto"
    >
      <Flex
        display="flex"
        justifyContent="space-between"
        flexWrap="wrap"
        py={20}
      >
        <Box>
          <Image src={OldRepublic} alt="Old Republic Title " />
        </Box>
        <Box py="10px">
          <Image src={TLTAMember} alt="TLTA" />
        </Box>
      </Flex>
    </Heading>
  </>
);

const ImportanceOfInsurance = () => (
  <Popover>
    <PopoverTrigger>
      <Button variant="btnOne">Why is insurance important?</Button>
    </PopoverTrigger>
    <Portal>
      <PopoverContent>
        <Accordion allowToggle>
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box flex='8' textAlign='left'>
                Do I need an Owner's Title Insurance Policy?
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
            Most definitely! Title insurance is a means of protecting yourself from financial loss in the event that problems
            develop regarding the rights to ownership of your property. There may be hidden title defects that even the most
            careful title search will not reveal. In addition to protection from financial loss, Title Insurance pays the cost
            of defending against any covered claim.
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box flex='8' textAlign='left'>
                How will Owner's Title Insurance protect me?
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
            Some common examples of problems covered by an Owner's Title Insurance Policy include:
            <UnorderedList>
              <ListItem>Improper execution of documents</ListItem>
              <ListItem>Unreleased mortgages</ListItem>
              <ListItem>Mistakes in recording or indexing of legal documents</ListItem>
              <ListItem>Mental incompetence of grantors on the deed</ListItem>
              <ListItem>Forgeries and fraud</ListItem>
              <ListItem>Impersonation of the true owners of the land by fraudulent persons</ListItem>
              <ListItem>Undisclosed or missing heirs</ListItem>
              <ListItem>Unpaid taxes and assessments</ListItem>
              <ListItem>Refusal of potential purchaser to accept title based upon condition of title</ListItem>
              <ListItem>Unpaid judgments and liens</ListItem>
            </UnorderedList>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box flex='8' textAlign='left'>
                What can make the Title to my home defective?
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
            When you purchase your home, you take “title” to your property. Any number of problems that remain undisclosed
            after even the most meticulous search of public records can make a title defective. These hidden “defects” are
            dangerous indeed because you may not learn of them for many months or years. Yet they could force you to spend
            substantial sums on a legal defense and still result in the loss of your property.
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box flex='8' textAlign='left'>
                Won't the Lender’s Title insurance Policy protect me?
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
            No, there are two types of Title Insurance. Your lender likely will require that you purchase a Lender's Title Insurance
            Policy. This policy only insures that the lender has a valid, enforceable lien on the property. Most lenders require this
            type of insurance, and typically require the borrower to pay for it.<br/><br/>

            An Owner's Title Insurance Policy, on the other hand, is designed to protect you from title defects that existed prior
            to the issue date of your policy. Title troubles, such as improper estate proceedings or pending legal action, could
            put your equity at serious risk. If a valid claim is filed, in addition to financial loss up to the face amount of the policy,
            your Owner's Title Insurance Policy covers the full cost of any legal defense of your title.
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box flex='8' textAlign='left'>
                How much does Title Insurance cost?
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
            The one-time premium is directly related to the value of your home. Typically, it is less expensive than your annual
            auto insurance. it is a one-time only expense, paid when you purchase your home. Yet it continues to provide
            complete coverage for as long as you or your heirs own the property.
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </PopoverContent>
    </Portal>
  </Popover>
);

const WhoWeAre = () => (
  <Box py={20}>
    <Container maxW="container.xl">
      <AnimatedFadeUpBox
        display="flex"
        showOffset="300"
        justifyContent="space-between"
        flexWrap="wrap"
        mb={10}
      >
        <Box flexBasis={{ base: '100%', md: '50%' }} pr={6}>
          <Heading as="h3" size="2xl" className="readable">
            Why Choose Cowboy Country Title
          </Heading>
          <Box
            borderRadius="100%"
            backgroundColor="brand.five"
            width="150px"
            height="150px"
            mb={6}
          >
            <SheriffBadgeOne m="auto" />
          </Box>
        </Box>
        <Box
          flexBasis={{ base: '100%', md: '50%' }}
          mt={{ base: 10, md: 0 }}
          pl={{ base: 0, md: 6 }}
        >
          <Text fontSize="xl" className="readable" mt="100px">
            As an agent of Old Republic Title, a national underwriter
            with years of experience, Cowboy Country Title provides
            assurance and peace of mind to our clients by providing
            their title insurance promptly and efficiently.
          </Text>
          <ImportanceOfInsurance />
        </Box>
      </AnimatedFadeUpBox>
    </Container>
  </Box>
);

const Home = () => {
  return (
    <Box position="relative" pb={20}>
      <HomeHeroSection />
      <WelcomeSection />
      <SectionA />
      <WhoWeAre />
      <SectionB />
      <Box
        minH="400px"
        backgroundColor="brand.three"
        position="relative"
        zIndex="0"
        overflow="hidden"
      >
        <Container maxW="container.md" py={20}>
          <WhoWeWorkedWith />
        </Container>
        <Box
          pos="absolute"
          bottom="0"
          left="0"
          zIndex="-1"
          width="100vw"
          height="200px"
        >
          <Hill2 />
        </Box>
      </Box>
    </Box>
  );
};

export default Home;
