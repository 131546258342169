export const FinancingPurchaseByBusiness = {
  Name: 'Commercial Financed Purchase - by Business Entity',
  BuyerForms: [
    'Real Estate Lien Note    (lender fee)',
    'Deed of Trust    (lender fee)',
    'Environmental Addendum    (lender fee)',
    'Adjustable Rate Loan Rider    (lender fee)',
    'Disclaimer of Oral Agreements    (lender fee)',
    'Guaranty Agreement    (lender fee)',
    'Certificate of Resolutions    (included)',
    'Business Purpose Statement    (lender fee)',
    'Assignment of Rent, Income and Receipts    (lender fee)',
    'Certificate of Account Status from Secretary of State    (no charge)',
    'Certificate of Formation    (Buyer to furnish)',
    'Company Agreement\\Bylaws    (Buyer to furnish)',
    'Waiver of Inspection    (no charge)',
    'Recording Fee    (included)',
    'Closing Fee    (included)',
    'Overnight/Wire Fee    (if applicable)',
  ],
  BuyerPrice: '$425.00',
  SellerForms: [
    'Warranty Deed (Special or General)    (included)',
    'Affidavit of Debts and Liens    (no charge)',
    'IRS 1099    (no charge)',
    'T-47 (Survey Affidavit)    (no charge)',
    'Tax Certificate    (included)',
    'Closing Fee    (included)',
    'Overnight/Wire Fee    (if applicable)',
  ],
  SellerPrice: '$425.00',
};
