import {
  Box,
  Container,
  Flex,
  Stack,
  Text,
  Link,
  Button,
} from '@chakra-ui/react';
import { FaFacebook, FaInstagram, FaTwitter } from 'react-icons/fa';
import { MdEmail } from 'react-icons/md';
import { generate } from 'shortid';
import moment from 'moment';

const FooterLinks = {
  External: [
    {
      name: 'Erath County Clerk',
      description: '',
      url: 'https://tyler.co.erath.tx.us/web/user/disclaimer',
      id: generate(),
    },
    {
      name: 'Erath County Appraisal District',
      description: '',
      url: 'https://erath-cad.com/',
      id: generate(),
    },
    {
      name: 'Texas Land Title Association',
      description: '',
      url: 'https://www.tlta.com/',
      id: generate(),
    },
    {
      name: 'Title Insurance (Basic Manual)',
      description: '',
      url: 'https://www.tdi.texas.gov/title/titleman.html',
      id: generate(),
    },
    {
      name: 'Texas Real Estate Commission - Contract Forms',
      description: '',
      url: 'https://www.trec.texas.gov/',
      id: generate(),
    },
    {
      name: 'Stephenville Chamber of Commerce',
      description: '',
      url: 'https://www.stephenvilletexas.org/',
      id: generate(),
    },
  ],
  Internal: [
    {
      name: 'Home',
      description: '',
      url: '/',
      id: generate(),
    },
    {
      name: 'Our Team',
      description: '',
      url: '/OurTeam',
      id: generate(),
    },
    {
      name: 'Mortgage Calculator',
      description: '',
      url: '/MortgageCalculator',
      id: generate(),
    },
    {
      name: 'Closing Cost Packages',
      description: '',
      url: '/PackageCosts',
      id: generate(),
    },
  ],
};

const ListHeader = ({ children, ...rest }) => {
  return (
    <Text {...rest} fontSize="lg" mb={2}>
      {children}
    </Text>
  );
};

const SocialMediaLinks = () => {
  return (
    <Flex flexGrow="1" flexDir="column">
      <ListHeader
        variants="itemOne"
        color="gray.300"
        fontWeight="semibold"
        mb={10}
      >
        Social
      </ListHeader>
      <Stack
        spacing={8}
        align="center"
        justify={['center', 'flex-start', 'flex-start']}
        direction={['row', 'row']}
        pt={[4, 4, 0, 0]}
      >
        <Link
          target="_blank"
          href="https://www.facebook.com/CowboyCountryTitle/"
        >
          <FaFacebook size="1.5em" />
        </Link>
        <Link
          target="_blank"
          href="https://www.instagram.com/cowboycountrytitle/"
        >
          <FaInstagram size="1.5em" />
        </Link>
        <Link target="_blank" href="https://twitter.com/CowboyTitle">
          <FaTwitter size="1.5em" />
        </Link>
        <Link
          target="_blank"
          href="mailto:info@cowboycountrytitle.com"
        >
          <MdEmail size="1.5em" />
        </Link>
      </Stack>
    </Flex>
  );
};

const AllLinks = () => {
  return (
    <Box flexWrap="wrap" display="flex">
      <Stack flexGrow="1" align="flex-start" py={6}>
        <ListHeader
          variants="itemOne"
          color="gray.300"
          fontWeight="semibold"
        >
          Cowboy Country Links
        </ListHeader>
        {FooterLinks.Internal.map((intLink) => (
          <Link
            exact
            href={intLink.url}
            target="_self"
            display="block"
          >
            {intLink.name}
          </Link>
        ))}
        ;
      </Stack>
      <Stack flexGrow="1" align="flex-start" py={6}>
        <ListHeader
          variants="itemOne"
          color="gray.300"
          fontWeight="semibold"
        >
          External Resources
        </ListHeader>
        {FooterLinks.External.map((extLink) => (
          <Link
            isExternal
            width="100%"
            href={extLink.url}
            target="_blank"
            display="block"
            whiteSpace="initial"
            color="brand.three"
          >
            <Button width="75%" whiteSpace="initial" textAlign="left">
              {extLink.name}
            </Button>
          </Link>
        ))}
        ;
      </Stack>
      <SocialMediaLinks />
    </Box>
  );
};

const SubFooter = () => {
  return (
    <Flex
      alignContent="center"
      justifyContent="space-between"
      p={1}
      color="brand.zero"
      pt={10}
    >
      <Box display="flex" pr={2}>
        <Text fontSize="xs" mb={0} color="brand.zero">
          Cowboy Country Title &copy; {moment().format('YYYY')}
        </Text>

        <Link color="brand.zero" ml={2} fontSize="xs" to="/privacy">
          Privacy Policy
        </Link>
      </Box>
    </Flex>
  );
};

const Footer = (props) => {
  return (
    <Box backgroundColor="brand.three" color="gray.200">
      <Container maxW="container.xl" py={20}>
        <AllLinks />

        <SubFooter />
      </Container>
    </Box>
  );
};

export default Footer;
