import { generate } from 'shortid';
import {
  Box,
  Container,
  ListItem,
  Heading,
  UnorderedList,
  Link,
  Divider,
  AspectRatio,
} from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { Card } from '../../_index';
import PremiumRates from './PremiumRates';
import { HomeHeroBGXl } from '../../../Assets/illustration/home-ill';

const resourceItems = {
  'Other Resources': [
    {
      name: 'Erath County Clerk',
      description: '',
      url: 'https://tyler.co.erath.tx.us/web/user/disclaimer',
      id: generate(),
    },
    {
      name: 'Erath County Appraisal District',
      description: '',
      url: 'https://erath-cad.com/',
      id: generate(),
    },
    {
      name: 'Texas Land Title Association',
      description: '',
      url: 'https://www.tlta.com/',
      id: generate(),
    },
    {
      name: 'Title Insurance (Basic Manual)',
      description: '',
      url: 'https://www.tdi.texas.gov/title/titleman.html',
      id: generate(),
    },
    {
      name: 'Texas Real Estate Commission - Contract Forms',
      description: '',
      url: 'https://www.trec.texas.gov/',
      id: generate(),
    },
    {
      name: 'Stephenville Chamber of Commerce',
      description: '',
      url: 'https://www.stephenvilletexas.org/',
      id: generate(),
    },
  ],
};

const Resources = () => {
  const sections = Object.keys(resourceItems);
  return (
    <Box py={20} position="relative">
      <Container maxW="container.xl">
        <Box
          borderRadius="10px"
          backgroundColor="white"
          width="100%"
          py={10}
          px={{ base: 2, md: 6 }}
        >
          <Card
            mx={{ base: 'auto' }}
            width={{
              base: '100%',
              md: '90%',
              lg: '80%',
              xl: '60%',
            }}
          >
            <Heading as="h1" size="3xl" ml="10%">
              Where to find us
            </Heading>
            <AspectRatio ratio={4 / 3}>
              <iframe
                title="Where you can find us"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d975.0316509288407!2d-98.2223100399197!3d32.21225573691141!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8651b132b6c03d1f%3A0xdb7f0decb21835a4!2sCowboy%20Country%20Title%2C%20LLC!5e0!3m2!1sen!2sus!4v1632946694590!5m2!1sen!2sus"
                width="auto"
                maxHeight="450"
                loading="lazy"
                alt="demo"
              />
            </AspectRatio>
          </Card>
          <Heading as="h1" size="3xl">
            More Resources
          </Heading>
          {sections.map((s) => (
            <>
              <UnorderedList styleType="none">
                {resourceItems[s].map((i) => (
                  <ListItem mb={4}>
                    <Link href={i.url} target="_blank">
                      {i.name}
                      <ExternalLinkIcon ml={2} />
                    </Link>
                  </ListItem>
                ))}
              </UnorderedList>
            </>
          ))}
          <Divider my={6} />
          <PremiumRates />
        </Box>
      </Container>
      <Box
        pos="absolute"
        bottom="0"
        left="0"
        zIndex="-1"
        width="100vw"
        overflow="hidden"
      >
        <HomeHeroBGXl width="100vw" height="auto" />
      </Box>
    </Box>
  );
};

export default Resources;
