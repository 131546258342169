import { Box } from '@chakra-ui/react';
import { Link as AppLink } from 'react-router-dom';
import HeaderContact from './HeaderContact';
import { MarkOne, MarkTwo } from '../_index';

const MarkElement = ({ ...rest }) => {
  return (
    <Box {...rest}>
      <Box
        as={AppLink}
        to="/"
        display={{ base: 'none', md: 'none', lg: 'block' }}
      >
        <MarkOne p={2} height="auto" width="200px" />
      </Box>
      <Box
        as={AppLink}
        to="/"
        display={{ base: 'block', md: 'block', lg: 'none' }}
      >
        <MarkTwo
          height="auto"
          width="200px"
          p={2}
        />
      </Box>

    </Box>
  );
};

export default MarkElement;
