import {
  Box, Flex, Link, Container,
} from '@chakra-ui/react';
import { Link as AppLink } from 'react-router-dom';
import { generate } from 'shortid';
import MarkElement from './NavMark';
import MenuToggle from './toggle';
import HeaderContact from './HeaderContact';

const ROUTES = [
  {
    name: 'Home',
    path: '/',
    key: generate(),
  },
  {
    name: 'Place New Order',
    path: '/RequestForm',
    key: generate(),
  },
  {
    name: 'Mortgage Calculator',
    path: '/MortgageCalculator',
    key: generate(),
  },
  {
    name: 'Closing Cost Packages',
    path: '/PackageCosts',
    key: generate(),
  },
  {
    name: 'Our Team',
    path: '/OurTeam',
    key: generate(),
  },
  {
    name: 'More Title Resources',
    path: '/resources',
    key: generate(),
  },
];

const MenuLinks = ({ toggle, isOpen }) => {
  return (
    <Box
      display={{ base: 'block', lg: 'flex' }}
      flexDirection="column"
      justifyContent="space-between"
      width="100%"
      top="0px"
      zIndex="9"
      left="0px"
      height={{ base: isOpen ? '100vh' : '0vh', lg: 'auto' }}
      overflow="hidden"
      backgroundColor="brand.five"
      position={{ base: 'fixed', lg: 'static' }}
    >
      {/* <Container maxW="container.xl" py={{ base: '0px' }}> */}
      <Flex
        justifyContent="space-between"
        alignItems="center"
      >
        <MarkElement />
        <Box display={{ base: 'none', lg: 'block' }}>
          <HeaderContact />
        </Box>
        <MenuToggle
          toggle={toggle}
          isOpen={isOpen}
        />
      </Flex>
      <Flex
        px={6}
        justifyContent="space-between"
        alignItems="flex-end"
        display={{ base: 'flex', lg: 'flex' }}
        flexDirection={{ base: isOpen ? 'column' : '', lg: 'row' }}
      >
        {ROUTES.map((r) => (
          <Link
            exact
            onClick={toggle}
            as={AppLink}
            variant="linkOne"
            to={r.path}
            mb={6}
          >
            {r.name}
          </Link>
        ))}
        <Box display={{ base: 'block', lg: 'none' }}>
          <HeaderContact />
        </Box>
      </Flex>
      {/* </Container> */}
    </Box>

  );
};

export default MenuLinks;
