import {
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  OrderedList,
  ListItem,
  Flex,
  Text,
  Divider,
  Heading,
  Tag,
  TagLabel,
} from '@chakra-ui/react';
import { generate } from 'shortid';
import {
  CashPurchaseByBusiness,
  CommercialCashPurchaseIndividual,
  CommercialFinancingPurchaseIndividual,
  FinancingPurchaseByBusiness,
  HomesteadCashPurchase,
  HomesteadFinancePurchase,
  RefinanceByBusiness,

} from './_index';
import { Card } from '../../../_index';

const packages = [
  CashPurchaseByBusiness,
  CommercialCashPurchaseIndividual,
  CommercialFinancingPurchaseIndividual,
  FinancingPurchaseByBusiness,
  HomesteadCashPurchase,
  HomesteadFinancePurchase,
  RefinanceByBusiness,
];

export const CalcRecordingFees = (NumPages) => {
  const total = 26 + (NumPages - 1) * 4;
  return total;
};

// When you go to building this out,
// the fields that need mapped in the objects are:
// Name:
// BuyerForms:
// BuyerPrice:
// SellerForms:
// SellerPrice:

// This should help so you don't have to go looking them up.

const Buyers = ({ pack }) => {
  return (
    <Card backgroundColor="gray.100" flexBasis={{ base: '100%', lg: '45%' }}>
      <Accordion allowToggle>
        <div key={generate}>
          <Divider orientation="horizontal" />
          <AccordionItem>
            {({ isExpanded }) => (
              <>
                <AccordionButton>
                  <Box
                    display="flex"
                    flex="1"
                    flexDir="row"
                    justifyContent="space-between"
                    alignItems="center"
                    py={6}
                  >
                    <Heading as="h4" size="lg" variant="sec">Buyers</Heading>

                    <Box>
                      {isExpanded ? (
                        <AccordionIcon />
                      ) : (
                        <AccordionIcon />
                      )}
                    </Box>
                  </Box>
                </AccordionButton>
                <Card backgroundColor="gray.100">
                  <AccordionPanel pb={4}>

                    <Box pr={10}>
                      <Tag
                        as="h4"
                        size="lg"
                        colorScheme="green"
                        borderRadius="full"
                        my={6}
                      >
                        <TagLabel>{pack.BuyerPrice}</TagLabel>
                      </Tag>
                      <OrderedList py={4} key={generate}>
                        {pack.BuyerForms.map((form) => {
                          return (
                            <ListItem key={generate}>
                              <Text variant="sec" fontSize="xl">
                                {form}
                              </Text>
                            </ListItem>
                          );
                        })}
                      </OrderedList>
                    </Box>

                  </AccordionPanel>
                </Card>
              </>
            )}
          </AccordionItem>
        </div>
      </Accordion>
    </Card>
  );
};

const Sellers = ({ pack }) => {
  return (
    <Card backgroundColor="gray.100" flexBasis={{ base: '100%', lg: '45%' }}>
      <Accordion allowToggle>
        <div key={generate}>
          <Divider orientation="horizontal" />
          <AccordionItem>
            {({ isExpanded }) => (
              <>
                <AccordionButton>
                  <Box
                    display="flex"
                    flex="1"
                    flexDir="row"
                    justifyContent="space-between"
                    alignItems="center"
                    py={6}
                  >
                    <Heading as="h4" size="lg" variant="sec">Sellers</Heading>
                    <Box>
                      {isExpanded ? (
                        <AccordionIcon />
                      ) : (
                        <AccordionIcon />
                      )}
                    </Box>
                  </Box>
                </AccordionButton>

                <AccordionPanel pb={4}>

                  <Box>

                    <Tag
                      as="h4"
                      size="lg"
                      colorScheme="green"
                      borderRadius="full"
                      my={6}
                    >
                      <TagLabel>{pack.SellerPrice}</TagLabel>
                    </Tag>
                    <OrderedList py={4} key={generate}>
                      {pack.SellerForms.map((form) => {
                        return (
                          <ListItem key={generate}>
                            <Text variant="sec" fontSize="xl">
                              {form}
                            </Text>
                          </ListItem>
                        );
                      })}
                    </OrderedList>
                  </Box>
                </AccordionPanel>
              </>
            )}
          </AccordionItem>
        </div>
      </Accordion>
    </Card>
  );
};

const DisplayPackagePricing = () => {
  return (
    <>

      {packages.map((pack) => {
        return (
          <Card backgroundColor="gray.100" mb={20} pt={10} px={6}>
            <Heading as="h4" size="xl">
              {pack.Name}
            </Heading>
            <Card display="flex" flexWrap="wrap" justifyContent="space-between">
              <Buyers pack={pack} />
              <Sellers pack={pack} />
            </Card>

          </Card>
        );
      })}
    </>
  );
};

export default DisplayPackagePricing;
