import React from 'react';
import {
  Heading,
  Box,
  Container,

} from '@chakra-ui/react';
import { ColorSection } from './ColorSection';
import { ButtonSection } from './ButtonsLinks';
import { ContentSection } from './Typography';
import { MarkSection } from './Mark';

const Styleguide = () => {
  return (
    <Box mheight="100vh" py={20}>
      <Container maxW="container.xl">
        <Heading as="h1" size="4xl" variant="ogg">
          StyleGuide
        </Heading>
        <Heading
          as="h2"
          variant="Alice"
          size="2xl"
          className="readable"
        >
          Collections of common styles used throughout the project
        </Heading>
        <MarkSection />
        <ColorSection />
        <ButtonSection />
        <ContentSection />
      </Container>
    </Box>
  );
};

export default Styleguide;
