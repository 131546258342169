export const CashPurchaseByBusiness = {
  Name: 'Cash Purchase by Business Entity',
  BuyerForms: [
    'Certificate of Resolutions   (included)',
    'Waiver of Inspection   (no charge)',
    'Certificate of Account Status from Secretary of State  (no charge)',
    'Certificate of Formation   (Buyer to furnish)',
    'Company Agreement\\Bylaws  (Buyer to furnish)',
    'Recording Fees   (included)',
    'Closing Fee  (included)',
    'Overnight/Wire Fee   (if applicable)',
  ],
  BuyerPrice: '$425.00',
  SellerForms: [
    'Waranty Deed (Special or General)  (included)',
    'Affidavit of Debts and Liens   (no charge)',
    'IRS 1099   (no charge)',
    'T-47 (Survey Affidavit)    (no charge)',
    'Tax Certificate  (included)',
    'Closing Fee  (included)',
    'Overnight/Wire Fee   (if applicable)',
  ],
  SellerPrice: '$375.00',
};
