import React from 'react';
import { Box, Container, Image } from '@chakra-ui/react';
import HomeHero from '../Subcomponents/HomeHero/HomeHero';


const HomeHeroSection = () => {
  return (
    <>
    <Box
      // minHeight="700px"
      position="relative"
      zIndex="0"
      minHeight="700px"
      bgGradient="linear(to-b, #FBD786 1%, #FFEFBA 10%, #FFFFFF 40%)"
    >
      <Container maxW="container.xl" py={10}>
        <HomeHero />
      </Container>
    </Box>
    </>
  );
};

export default HomeHeroSection
