import {Link, Text, Box} from '@chakra-ui/react'

const HeaderContact = () => (
  <Box display="block">
    <Text
      fontSize={{ base: 'lg', md: '2xl' }}
      textAlign={{ base: 'center', md: 'right' }}
      mb={{ md: '5px' }}
      mt={{ md: '-5px' }}
      mr={{ md: '20px' }}

    >
      Contact Us |&nbsp;
      <Link to="tel:2549686222">(254) 968-6222</Link><br/>
      <span>1915A, W Washington St,</span><br/>
      <span>Stephenville, TX 76401</span>
    </Text>
  </Box>
);

export default HeaderContact;
