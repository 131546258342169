export const Buttons = {
  baseStyle: {
    _focus: { boxShadow: 'none' },
    _hover: {
      boxShadow: 'md',
      textDecoration: 'none',
    },

    fontWeight: 600,
    minWidth: '10rem',
    paddingTop: '1rem',
    paddingBottom: '1rem',
    paddingLeft: '.5rem',
    paddingRight: '.5rem',
  },
  sizes: {
    lg: {
      borderRadius: '10px',
    },
    md: { borderRadius: '10px' },
    sm: { borderRadius: '10px' },
    xs: { borderRadius: '10px' },
  },
  variants: {
    btnCTA: {

      letterSpacing: '.1rem',
      transition: 'all 0.15s ease-out',
      backgroundColor: 'brand.two',
      textTransform: 'uppercase',
      color: 'white',
      _hover: {
        backgroundColor: 'brand.one',
        transition: 'all 0.15s ease-out',

      },
      _active: {
        backgroundColor: 'brand.three',
        transition: 'all 0.15s ease-out',
      },
    },
    btnOne: {
      color: 'brand.dark',
      backgroundColor: 'brand.four',
    },
    btnTwo: {
      // backgroundColor: 'transparent',
      color: 'brand.black',
      border: '2px',

      borderColor: 'brand.three',
    },
  },
};

export const Links = {
  baseStyle: {
    transition: 'all 0.15s ease-out',
    textDecoration: 'none',
    _hover: {
      textDecoration: 'none',
    },
  },
  sizes: {
    lg: {
      fontSize: 'lg',
    },
    md: {
      fontSize: 'md',
    },
  },
  variants: {
    linkOne: {
      paddingRight: '1rem',
      paddingLeft: '1rem',
      position: 'relative',
      fontWeight: 600,
      letterSpacing: '.1rem',
      _after: {
        transition: 'all 0.15s ease-out',
        zIndex: '-1',
        // bgGradient: 'linear(to-br, , brand.one 80%,  brand.three )',
        backgroundColor: 'brand.one',
        borderRadius: '10px',
        position: 'absolute',
        content: '""',
        height: '5px',
        width: '100%',
        paddingRight: '2rem',
        paddingLeft: '2rem',
        bottom: '-5px',
        left: '0%',
        color: 'red.100',
      },
      _hover: {
        // color: 'brand.one',
        _after: {
          zIndex: '-1',
          backgroundColor: 'brand.one',
          // bgGradient: 'linear(to-br,  brand.three 80%,  brand.one )',
          position: 'absolute',
          content: '""',
          height: '40%',
        },
      },
    },
  },
};
