/* eslint-disable no-multiple-empty-lines */

function calculateprincipal(monthlyInterest, months, payment) {
  const principal = payment
    / ((monthlyInterest * (1 + monthlyInterest) ** months)
      / ((1 + monthlyInterest) ** months - 1));
  return principal;
}

function calculatePayment(monthlyInterest, months, principal) {
  // M = P * r * (1 + r)n / [(1 + r)n – 1]
  const payment = (principal * monthlyInterest * (1 + monthlyInterest) ** months)
    / ((1 + monthlyInterest) ** months - 1);
  return payment;
}

export function Calculate(apr, years, principal, down, payment) {

  // months == months/12
  const months = years * 12;
  // monthlyInterest == interst per month or apr/12
  const monthlyInterest = apr / 12 / 100;
  // adjust principal by the down payment amount
  const adjustedprincipal = principal ? principal - down : null;

  switch (!principal || !payment) {
    case !principal:
      return calculateprincipal(monthlyInterest, months, payment);
    case !payment:
      return calculatePayment(
        monthlyInterest,
        months,
        adjustedprincipal,
      );
    default:
      return null;
  }
}
