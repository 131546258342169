import { Container, Heading } from '@chakra-ui/react';

const PageNotFound = () => {
  return (
    <div>
      <main>
        <Container>
          <Heading as="h1" size="2xl">404</Heading>
          <Heading as="h2" size="xl">
            The page you're looking for does not exist.
          </Heading>
        </Container>
      </main>
    </div>
  );
};

export default PageNotFound;
