export const HomesteadFinancePurchase = {
  Name: 'Homestead Purchase with Financing',
  BuyerForms: [
    'Real Estat Lien Note    (lender fee)',
    'Deed of Trust    (lender fee)',
    'Environmental Addendum - If requested by lender    (lender fee)',
    'Adjustable Rate Loan Rider    (lender fee)',
    'Disclaimer of Oral Agreements - over %15,000.00    (lender fee)',
    'Notice of Penalties for Making False or Misleading Written Statements    (lender fee)',
    'Waiver of Inspection    (no charge)',
    'Recording Fees    (included)',
    'Closing Fees    (included)',
    'Overnight/Wire Fee    (if applicable)',
  ],
  BuyerPrice: '$300.00',
  SellerForms: [
    'Warranty Deed (Special or General)    (included)',
    'Affidavit of Debts and Liens    (no charge)',
    'IRS 1099    (no charge)',
    'T-47 (Survey Affidavit) - If using existing survey    (no charge)',
    'Tax Certificate    (included)',
    'Closing Fees    (included)',
    'Overnight/Wire Fee    (if applicable)',
  ],
  SellerPrice: '$425.00',
};
