import {
  Heading,
  Box,
  Divider,
  Flex,
  Container,
  Image,
  Text,
  Link
} from '@chakra-ui/react';
import { generate } from 'shortid';
import {
  LinkPhone,
  LinkEmail,
} from '../../_index';
//import lorena_mercado from '../../../Assets/Images/Profile/lorena_mercado.jpg';
import phillip_warren from '../../../Assets/Images/Profile/phillip_warren.jpg';
import lauren_brown from '../../../Assets/Images/Profile/lauren_brown.jpeg';
//import tiffany_ivey from '../../../Assets/Images/Profile/tiffany_ivey.png';
//import chris_hoffman from '../../../Assets/Images/Profile/chris_hoffman.jpg';
//import julie_batten from '../../../Assets/Images/Profile/julie_batten.jpg';
//import paige_wallace from '../../../Assets/Images/Profile/paige_wallace.jpg';
//import karley_pollock from '../../../Assets/Images/Profile/karley_pollock.jpg';
//import regina_metting from '../../../Assets/Images/Profile/regina_metting.jpg';
import michelle_gandy from '../../../Assets/Images/Profile/michelle_gandy.jpg';
import { HomeHeroBGXl } from '../../../Assets/illustration/home-ill';
import { MdPhonelink } from 'react-icons/md';

const TeamMembers = [
  {
    image: phillip_warren,
    name: 'Phillip Warren',
    title: 'Lead Abstractor',
    phone: '(254) 968-6222',
    email: 'Phillip@cowboycountrytitle.com',
    profile:
      'Phillip is a Stephenville native and is the Manager for our office. Phillip is a third-generation abstractor in his family and loves our local history and tracing out the chain of title and searching fill in the gaps. Phillip has been with our office since day one and oversees the maintenance and expansion of the database. Phillip is knowledgeable in plotting, deed chain, surveys, and taking titles back to sovereignty. When Phillip is not in the office you can find him with his wife and daughter, hanging out with his German Shepherds down by the creek or off roading in Arkansas.',
  },
  {
    image: lauren_brown,
    name: 'Lauren Brown',
    title: 'Escrow Officer',
    phone: '(254) 968-6222',
    email: 'Lauren@cowboycountrytitle.com',
    profile:
      'Lauren is one of our Escrow Officers at Cowboy Country Title. Lauren obtained her Bachelors of Arts Degree specializing in Communications from Purdue University, in West Lafayette, IN. Lauren is married to Kirt Brown, they have one daughter, Caroline, along with a whole lot of animals including dogs, cats, rabbits, and cows. They moved to Stephenville in 2018 and fell in love with the community. ',
  },
  {
    image: michelle_gandy,
    name: 'Michelle Gandy',
    title: 'Escrow Officer',
    phone: '(254) 968-6222',
    email: 'Michelle@cowboycountrytitle.com',
    profile:
      'Michelle is a licensed Escrow Officer and our Closer at Cowboy Country Title.  Michelle started in the title insurance industry in the Austin area in 1996 and has extensive knowledge and experience in title insurance and the closing process.  She has been closing all types of real estate transactions for over 25 years.  Her goal is to communicate with and educate parties throughout the transaction to make closing a smooth and enjoyable process. Michelle is a Florida native but has been in Texas since 1985 when her family moved to Stephenville where she attended Tarleton State University.  After living in Dallas, Austin and Wimberley, she made her way back to the Stephenville area and has been living in Comanche since 2010.  She has two adult daughters and a grandson and enjoys spending as much time with them as possible.',
  },
  //{
  //  image: chris_hoffman,
  //  name: 'Chris Hoffman',
  //  title: 'Abstractor/Escrow Officer',
  //  phone: '(254) 968-6222',
  //  email: 'Chris@cowboycountrytitle.com',
  //  profile:
  //    'Chris brings over 14 years of real estate knowledge to Cowboy Country Title and has lived in Erath Country since 2005. His hobbies include a ranching lifestyle with a background in the cattle and equine industries. Chris mainly works in the Abstracting department but is a licensed Escrow Officer to help when we get busy. Chris is knowledgeable in multiple areas of the title industry such as deed chains, running out title, heirship issues and many other things.',
  //},
  //{
  //  image: lorena_mercado,
  //  name: 'Lorena Mercado',
  //  title: 'Escrow Officer',
  //  phone: '(254) 968-6222',
  //  email: 'Lorena@cowboycountrytitle.com',
  //  profile:
  //    'Lorena Mercado is a licensed Escrow Officer and Notary Public at Cowboy Country Title, LLC. Being bilinugual she assists our Spanish speaking clients with clarity and understanding throughout the closing process. Lorena moved to Erath County, Texas from California and brought her extensive experience in Loan Processing to the Title company. Lorena is married and enjoys cheering on her 6 children in their sporting events and enjoy spending time with her family.',
  //},

  //{
  //  image: julie_batten,
  //  name: 'Julie Batten',
  //  title: 'Abstractor',
  //  phone: '(254) 968-6222',
  //  email: 'Julie@cowboycountrytitle.com',
  //  profile:
  //    'Julie is one of our Title Abstractors for Cowboy Country Title. Julie graduated with her Bachelor of Business Administration focused in Accounting in 2020 from Tarleton State University. Julie brings over 7 years of experience in bank operations. Julie is married to Aubrey Batten and has three children Clayton, Grayson, and Violet. Her and her family moved to Stephenville in 2016 from west Texas. In her free time Julie enjoys gardening, DIY projects, and spending time with her family and pets.',
  //},
  //{
  //  image: paige_wallace,
  //  name: 'Paige Wallace',
  //  title: 'Escrow Officer',
  //  phone: '(254) 968-6222',
  //  email: 'Paige@cowboycountrytitle.com',
  //  profile:
  //    'Paige is one of our Escrow Assistants here at Cowboy Country Title. She studied at Kansas State University where she received her degree in Agribusiness. Paige brings extensive experience in the mortgage industry and is a great addition to our team. She is eager to help in any area within the escrow process as well as working with clients to make the process as easy as possible. Paige moved from Kansas to the Stephenville area in 2019 and has loved it ever since. In her free time, she enjoys spending time with her daughter, as well as caring for all her animals.',
  //},
  //{
  //  image: karley_pollock,
  //  name: 'Karley Pollock',
  //  title: 'Receptionist/Escrow Accountant',
  //  phone: '(254) 968-6222',
  //  email: 'Karley@cowboycountrytitle.com',
  //  profile:
  //    'Karley is our receptionist at Cowboy Country Title as well as an Escrow Assistant. She is currently attending Ranger College and plans to finish her bachelor’s degree in Zoology at Tarleton State University. Karley assists in any aspect she can at the office and ensures a welcoming environment for anyone who walks through the door. She moved from Colorado to Stephenville in January of 2022 for school and rodeo and has fallen in love with Texas. In her free time, Karley enjoys spending time with her roommates as well as caring for all her horses.',
  //},

];

const Card = ({ Content, children, ...rest }) => (
  <Box borderRadius="10px" {...rest}>
    {Content || children}
  </Box>
);

const Section = ({ member, reverse, ...rest }) => {
  const {
    name, image, title, profile, phone, email,
  } = member;
  return (
    <Box

      {...rest}
      pos="relative"
      overflow="hidden"
      borderRadius="10px"
      p={{ base: 4, md: 10 }}
      mb={10}
      display="flex"
      flexWrap="wrap"
    >
      <Box
        width={{ base: '100%', lg: '50%' }}
        py={4}
        pr={{ base: 0, md: 4 }}
      >
        <Heading as="h1" size="2xl" mb={2}>
          {name}
        </Heading>
        <Divider width="50%" mb={6} />
        <Box display="flex" mb={10}>
          <Image
            borderRadius="full"
            sizes="md"
            src={image}
            alt={`An image of ${name}`}
          />
        </Box>
        <Box>
          <Heading variant="sec" size="lg">
            {title}
          </Heading>
          <Flex flexDir="column">
            <LinkEmail>{email}</LinkEmail>
            <LinkPhone>{phone}</LinkPhone>
          </Flex>
        </Box>
      </Box>

      <Box
        py={{ base: 6, md: 10 }}
        width={{ base: '100%', lg: '50%' }}
        bgColor="white"
        borderRadius="10px"
        p={4}
        px={{ base: 10 }}
      >
        <Heading variant="sec" size="lg" mb={2}>
          About
          {' '}
          {name}
        </Heading>
        <Divider width="50%" mb={6} />
        <Text fontSize="lg">{profile}</Text>
      </Box>
    </Box>
  );
};

const OurTeam = () => {
  return (
    <Box py={20} position="relative">
      <Container maxW="container.xl">
        {TeamMembers.map((member) => (
          <Box
            // minH={{ base: '600px', md: '400px' }}
            pos="relative"
            key={generate}
            mb={10}
          >
            <Card mx={{ base: 'auto' }}>
              <Section
                // minH={{ base: '600px', md: '400px' }}
                member={member}
                backgroundColor="brand.five"
              />
            </Card>
          </Box>
        ))}
      </Container>
      <Box
        pos="absolute"
        bottom="0"
        left="0"
        zIndex="-1"
        width="100vw"
        overflow="hidden"
      >
        <HomeHeroBGXl width="100vw" height="auto" />
      </Box>
    </Box>
  );
};

export default OurTeam;
