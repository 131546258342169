import {
  Box, Container, Heading, Text, Image,
} from '@chakra-ui/react';
import React from 'react';
import DreamLandscape from '../../../Assets/Images/dream-landscape.jpg';
import {
  AnimatedFadeUpBox,
  FullBleedContentImageSection,
} from '../../_index';

const WeAreHere = () => (
  <Box
    backgroundColor="brand.two"
    py={10}
    pr={4}
    width={{ base: '100%', md: '60%' }}
    minHeight="500px"
  >
    <AnimatedFadeUpBox showOffset="300">
      <Heading color="brand.five" as="h3" size="2xl" mb={10}>
        We are here with you
      </Heading>

      <Text color="white" fontSize="2xl" className="readable" mb={6}>
      We are fast, honest and friendly and we will 
      keep you informed as to your transaction status during the entire closing process. You have chosen a 
      good partner - so relax!
      </Text>
    </AnimatedFadeUpBox>
  </Box>
);

const SectionB = () => {
  return (
    <FullBleedContentImageSection
      backgroundColor="brand.two"
      contentBlock={<WeAreHere />}
      bleedBlock={
        <Box width={{ base: '800px', md: '900px', lg: '800px' }}>
          <Image
            transform={{ base: 'scaleX(1)', md: 'scaleX(-1)' }}
            src={DreamLandscape}
            width="100%"
            height="100%"
            alt="Cowboy sleeping under a tree"
          />
        </Box>
      }
    />
  );
};

export default SectionB;
