import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import {
  Box,
  FormControl,
  NumberInput,
  NumberInputField,
  Heading,
  Divider,
  InputRightAddon,
  InputLeftAddon,
  InputGroup,
  FormLabel,
  Container,
} from '@chakra-ui/react';
import { Calculate } from './Calculators';
import { HomeHeroBGXl } from '../../../Assets/illustration/home-ill';
import { HouseOne } from '../../../Assets/illustration/homes';

const defaultValues = {
  principal: 220000,
  down: 10000,
  apr: 3.5,
  years: 30,
  payment: 840,
};

const MortgageCalculator = () => {
  const {
    register,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    reValidateMode: 'onChange',
    defaultValues,
  });

  const handleChange = (p, d, a, y) => {
    const payment = Calculate(a, y, p, d);
    setValue('payment', parseInt(payment));
  };

  return (
    <Box
      py={20}
      position="relative"
      minHeight="700px"
      zIndex="0"
      bgGradient="linear(to-b, #FBD786 1%, #FFEFBA 10%, #FFFFFF 40%)"
    >
      <Container maxW="container.lg">
        <Heading as="h1" size="3xl" mb={16}>
          Mortgage Calculator
        </Heading>
      </Container>
      <Container
        maxW="container.lg"
        display="flex"
        justifyContent="space-between"
        flexWrap="wrap"
        backgroundColor="white"
        borderRadius="md"
        p={10}
      >
        <Container maxW="container.lg">
          <Heading as="h4" size="xl" mb={6}>
            Insert loan information below for an estimated monthly
            payment
          </Heading>
        </Container>
        <Box mb={10}>
          <form>
            <FormControl mb={6} isRequired>
              <FormLabel>Principle (Property Price)</FormLabel>
              <NumberInput>
                <InputGroup>
                  <InputLeftAddon children="$" />
                  <NumberInputField
                    size="lg"
                    fontSize="xl"
                    fontWeight="semibold"
                    id="principal"
                    name="principal"
                    type="numbers"
                    {...register('principal')}
                    onChange={(p) => {
                      handleChange(
                        p.target.value,
                        down.value,
                        apr.value,
                        years.value
                      );
                    }}
                  />
                </InputGroup>
              </NumberInput>
              {errors.principal ? (
                <span>{errors.principal.message}</span>
              ) : null}
            </FormControl>
            <FormControl mb={6} isRequired>
              <FormLabel>Down Payment</FormLabel>
              <NumberInput>
                <InputGroup>
                  <InputLeftAddon children="$" />
                  <NumberInputField
                    fontSize="xl"
                    fontWeight="semibold"
                    maxWidth={{ base: '80vw', md: '40vw' }}
                    type="numbers"
                    {...register('down')}
                    id="down"
                    onChange={(d) => {
                      handleChange(
                        principal.value,
                        d.target.value,
                        apr.value,
                        years.value
                      );
                    }}
                  />
                </InputGroup>
              </NumberInput>
              {errors.down ? (
                <span>{errors.down.message}</span>
              ) : null}
            </FormControl>
            <FormControl mb={6} isRequired>
              <FormLabel>Interest Rate (APR)</FormLabel>
              <NumberInput>
                <InputGroup>
                  <NumberInputField
                    fontSize="xl"
                    fontWeight="semibold"
                    maxWidth={{ base: '80vw', md: '40vw' }}
                    type="numbers"
                    {...register('apr')}
                    id="apr"
                    onChange={(a) => {
                      handleChange(
                        principal.value,
                        down.value,
                        a.target.value,
                        years.value
                      );
                    }}
                  />
                  <InputRightAddon children="%" />
                </InputGroup>
              </NumberInput>
              {errors.apr ? <span>{errors.apr.message}</span> : null}
            </FormControl>
            <FormControl mb={6} isRequired>
              <FormLabel>Length of Loan</FormLabel>
              <NumberInput>
                <InputGroup>
                  <NumberInputField
                    fontSize="xl"
                    fontWeight="semibold"
                    maxWidth={{ base: '80vw', md: '40vw' }}
                    type="numbers"
                    {...register('years')}
                    id="years"
                    onChange={(y) => {
                      handleChange(
                        principal.value,
                        down.value,
                        apr.value,
                        y.target.value
                      );
                    }}
                  />
                  <InputRightAddon children="years" />
                </InputGroup>
              </NumberInput>
              {errors.years ? (
                <span>{errors.years.message}</span>
              ) : null}
            </FormControl>
          </form>
        </Box>
        <Divider orientation="vertical" height="100%" />
        <Box>
          <Heading as="h4" size="lg" variant="sec">
            Estimated Monthly Payment
          </Heading>
          <Heading
            as="h5"
            size="3xl"
            fontFamily="Roboto"
            fontWeight="bold"
          >{`$ ${watch().payment}`}</Heading>
          <Divider mb={10} />
          <Box
            borderRadius="100%"
            backgroundColor="brand.five"
            width="150px"
            height="150px"
            mb={6}
          >
            <HouseOne />
          </Box>
        </Box>
      </Container>
      <Box
        pos="absolute"
        bottom="0"
        left="0"
        zIndex="-1"
        width="100vw"
        overflow="hidden"
      >
        <HomeHeroBGXl width="100vw" height="auto" />
      </Box>
    </Box>
  );
};

export default MortgageCalculator;
