import {
  Box, Flex, Heading, Button,
} from '@chakra-ui/react';
import { Card } from '../../../_index';

const HeroContent = () => (
  <Card padding={10}>
    <Heading as="h1" size="4xl">
      Closing Cost Package Pricing
    </Heading>
    <Heading as="h2" variant="sec" size="md" className="readable">
      Below is a list of common closing cost packages
      with their current value. The forms and costs for each
      package are listed in their dropdown.
    </Heading>

  </Card>
);

const EstimateHero = () => (
  <Flex flexWrap="wrap" justifyContent="space-between">
    <Box
      padding={4}
      flexGrow="1"
      minWidth={{ base: 'auto', md: '450px' }}
      flexBasis={{ base: '100%', md: '60%' }}
    >
      <HeroContent />
    </Box>
  </Flex>
);

export default EstimateHero;
