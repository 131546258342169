import { Route, Switch, Redirect } from 'react-router-dom';
import Packages from '../Pages/Packages/PackageCosts';
import Home from '../Pages/Home/Home';
import OurTeam from '../Pages/OurTeam/OurTeam';
import Styleguide from '../Pages/StyleGuide/Styleguide';
import MortgageCalculator from '../Pages/MortgageCalculator/MortgageCalculator';
import RequestForm from '../Pages/RequestForm/RequestForm';
import Resources from '../Pages/Resources/Resources';

import PageNotFound from "../PageNotFound/PageNotFound";

const Routes = () => {
  return (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/Styleguide" component={Styleguide} />
      <Route exact path="/PackageCosts" component={Packages} />
      <Route exact path="/Resources" component={Resources} />
      <Redirect exact from="/contact-us" to="OurTeam" />
      <Route exact path="/OurTeam" component={OurTeam} />
      <Route exact path="/RequestForm" component={RequestForm} />
      <Route exact path="/MortgageCalculator" component={MortgageCalculator} />
      <Route path="*">
        <PageNotFound />
      </Route>
    </Switch>
  );
};

export default Routes;
