import React from 'react';
import { Box, Container } from '@chakra-ui/react';
import { HalfByHalfSection } from '../../_index';
import { ReactComponent as MarkFull } from '../../../Assets/mark-1.svg';
import { ReactComponent as Mark } from '../../../Assets/mark-logo.svg';
import { ReactComponent as MarkTwo } from '../../../Assets/mark-2.svg';

export const MarkSection = () => {
  return (
    <Box>
      <Container centerContent py={10} maxW="container.xl">
        <MarkFull />
      </Container>
      <HalfByHalfSection
        left={(
          <Box py={10} display="flex" justifyContent="center" alignContent="center">
            <Mark />
          </Box>
        )} right={(
          <Box py={10} display="flex" justifyContent="center" alignContent="center">
            <MarkTwo />
          </Box>
        )}
      />
    </Box>
  );
};
