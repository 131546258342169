import * as React from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { BrowserRouter as Router } from 'react-router-dom';
import {
  MyFooter, MyNavbar, Helmet, ScrollButton,
} from '../_index';

import Routes from '../Routes/Routes';

const App = () => {
  return (
    <>
      <Helmet />
      <Router>
        <MyNavbar />
        <Flex flexDir="column" justifyContent="space-between" overflow="hidden" width="100vw">
          <main>
            <Routes />
          </main>
          <MyFooter />
        </Flex>

        <ScrollButton />

      </Router>
    </>
  );
};

export default App;
