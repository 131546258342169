import { CopyIcon, EmailIcon, PhoneIcon } from '@chakra-ui/icons';
import {
  Box,
  Center,
  Link,
  Flex,
  IconButton,
  Tooltip,
  useToast,
} from '@chakra-ui/react';
import React, { useState } from 'react';

import useClipboard from 'react-use-clipboard';
import {
  formatePhone,
  formatePhoneBot,
} from '../../../Helpers/index';

export const LinkPhone = (props) => {
  const { children, color, ...rest } = props;
  const formatedPhone = formatePhone(children);
  const formatedPhoneBot = formatePhoneBot(children);
  const [isCopied, setCopied] = useClipboard(children, {
    successDuration: 1000,
  });
  const toast = useToast();

  return (
    <>
      {formatedPhone ? (
        <Flex
          {...rest}
          display="inline"
          alignItems="center"
          justifyContent="space-between"
          minWidth="180px"
          wrap="nowrap"
        >
          <Link
            color={color}
            transition="all 0.4s ease-in-out"
            _hover={{ color: 'brand.two' }}
            href={`tel:+1${formatedPhoneBot}`}
          >
            <PhoneIcon />
            &nbsp;
            {formatedPhone}
          </Link>
          <Tooltip
            label={isCopied ? 'Copied' : 'copy to clipboard'}
            aria-label="copy to clipboard"
            placement="right"
          >
            <IconButton
              variant="none"
              aria-label="copy to clipboard"
              icon={<CopyIcon opacity="0.5" />}
              m={0}
              onClick={() => {
                setCopied();
                return toast({
                  title: 'Copied to Clipboard',
                  description: `${children} copied to clipboard`,
                  status: 'info',
                  variant: 'top-accent',
                  duration: 2000,
                  isClosable: true,
                });
              }}
            />
          </Tooltip>
        </Flex>
      ) : (
        <div />
      )}
    </>
  );
};

export const LinkEmail = (props) => {
  const { children, color, ...rest } = props;

  const [isCopied, setCopied] = useClipboard(children, {
    successDuration: 1000,
  });

  const toast = useToast();
  return (
    <Flex
      {...rest}
      display="inline"
      alignItems="center"
      justifyContent="space-between"
      minWidth="180px"
      wrap="nowrap"
    >
      <Link
        transition="all 0.4s ease-in-out"
        _hover={{ color: 'brand.two' }}
        color={color}
        href={`mailto:${children}`}
      >
        <EmailIcon />
        &nbsp;
        {children}
      </Link>
      <Tooltip
        label={isCopied ? 'Copied' : 'copy to clipboard'}
        aria-label="copy to clipboard"
        placement="right"
      >
        <IconButton
          m={0}
          variant="none"
          aria-label="copy to clipboard"
          icon={<CopyIcon opacity="0.5" />}
          onClick={() => {
            setCopied();
            return toast({
              title: 'Copied to Clipboard',
              description: `${children} copied to clipboard`,
              status: 'info',
              variant: 'top-accent',
              duration: 2000,
              isClosable: true,
            });
          }}
        />
      </Tooltip>
    </Flex>
  );
};
