import { useState } from 'react';
import { useWindowSize } from '../../Hooks/_index';

import MarkElement from './NavMark';
import MenuLinks from './MenuLinks';
import NavBarContainer from './NavBarContainer';
import MenuToggle from './toggle';

const NavBar = ({ ...rest }) => {
  const [isOpen, setIsOpen] = useState(false);
  const size = useWindowSize();
  const toggle = () => setIsOpen(!isOpen);

  return (
    <NavBarContainer
      {...rest}
      position="relative"
      boxShadow="md"
    >
      <MarkElement display={{ base: 'block', lg: 'none' }} />
      <MenuLinks
        isOpen={isOpen}
        toggle={toggle}
      />
      <MenuToggle
        toggle={toggle}
        isOpen={isOpen}
      />
    </NavBarContainer>
  );
};

export default NavBar;
