import React from 'react';
import { Helmet as Helm } from 'react-helmet';

const Helmet = ({ title, description }) => {
  const defaultTitle = 'Cowboy Country Title';
  const defaultDescription = 'We seeks to provide both an efficient and satisfactory closing experience to residential and commercial clients';
  const siteUrl = 'https://cowboytitle.com';

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    // image: `${siteUrl}${image || defaultImage}`,
    // url: `${siteUrl}${pathname}`,
  };
  return (
    <Helm>
      <title>{seo.title}</title>
      <meta name="description" content="Helmet application" />
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />

      {seo.url && <meta property="og:url" content={seo.url} />}

      {/* {(article ? true : null) && (
        <meta property="og:type" content="article" />
      )} */}

      {seo.title && <meta property="og:title" content={seo.title} />}

      {seo.description && (
        <meta property="og:description" content={seo.description} />
      )}

      {seo.image && <meta property="og:image" content={seo.image} />}

      <meta name="twitter:card" content="summary_large_image" />

      {/* {twitterUsername && (
        <meta name="twitter:creator" content={twitterUsername} />
      )} */}

      {seo.title && <meta name="twitter:title" content={seo.title} />}

      {seo.description && (
        <meta name="twitter:description" content={seo.description} />
      )}

      {seo.image && <meta name="twitter:image" content={seo.image} />}
      {/* <meta
        property="og:image"
        content={`${site.siteMetadata.siteUrl}/opengraph-gen.png`}
      /> */}
      <meta property="og:image:width" content="400" />
      <meta property="og:image:width" content="50" />
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
      <link href="https://fonts.googleapis.com/css2?family=Literata:wght@700;800;900&display=swap" rel="stylesheet" />
      <link href="https://fonts.googleapis.com/css2?family=Zilla+Slab:wght@600;700&display=swap" rel="stylesheet" />
      <link
        href="https://fonts.googleapis.com/css2?family=Roboto:wght@700;800;900&display=swap"
        rel="stylesheet"
      />
    </Helm>
  );
};

export default Helmet;
