export const CommercialFinancingPurchaseIndividual = {
  Name: 'Commercial Property Financing - by Individual',
  BuyerForms: [
    'Real Estate Lien Note    (lender fee)',
    'Deed of Trust    (lender fee)',
    'Environmental Addendum    (lender fee)',
    'Adjustable Rate Loan Rider    (lender fee)',
    'Disclaimer of Oral Agreements    (lender fee)',
    'Business Purpose Statement    (lender fee)',
    'Assignment of Rent, Income, and Receipts    (lender fee)',
    'Waiver of Inspection    (no charge)',
    'Recording Fee    (included)',
    'Closing Fee    (included)',
    'Overnight/Wire Fees    (if applicable)',
  ],
  BuyerPrice: '$300.00',
  SellerForms: [
    'Warranty Deed (Special or General)    (included)',
    'Affidavit of Debts and Liens    (no charge)',
    'IRS 1099    (no charge)',
    'T-47 (Survey Affidavit)    (no charge)',
    'Tax Certificate    (included)',
    'Closing Fee    (included)',
    'Overnight/Wire Fee    (if applicable)',
  ],
  SellerPrice: '$400.00',
};
