import React from 'react';
import {
  FormControl,
  Box,
  FormLabel,
  Divider,
  Stack,
  Text,
  Textarea,
  Input,
  Button,
  Container,
} from '@chakra-ui/react';
import { animated } from 'react-spring';
import axios from 'axios';
import { ReactComponent as Hill2 } from '../../../Assets/illustration/hill-xl-2.svg';
import { EmailIcon } from '@chakra-ui/icons';
import { useForm } from 'react-hook-form';
import { useRef, Header } from 'react';
import { generate } from 'shortid';
import { Card } from '../../_index';

const RequestForm = () => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
 
  const form = useRef();
  const handleChange = async (e) => {
    setValue('documentUpload', e.target.value.files, {shouldDirty: true});
  }

  const sendEmail = async (data) => {
    let form = document.querySelector('form');
    const formData = new FormData(form);

    const {documentsUpload} = data;

    for (let [index, value] of Object.entries(documentsUpload)){
      formData.append(value.name, documentsUpload[index]);
    }


    axios({
      method: 'post',
      url: '/send/',
      data: formData,
    }).then(async (res) => {
      const resData = await res;
      // console.log(resData);
      if (resData.status === 200) {
        alert("Message Sent");
      } else if (resData.status === "fail") {
        alert("Message failed to send");
      }
    })
  };

  const AnimatedBox = animated(Box);

  return (
    <Box py={5} position="relative">
      <Container maxW="container.xl">
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            flexWrap: 'nowrap',
            position: 'relative',
          }}
        >
          <AnimatedBox
            style={{
              width: '100%',
              height: '100%',
            }}
          >
            <Box>
              <form
                ref={form}
                encType="multipart/form-data"
                onSubmit={sendEmail}
              >
                <Card
                  key={generate()}
                  padding={10}
                  backgroundColor="brand.six"
                  mx={{ base: 'auto' }}
                  width="100%"
                >
                  <Stack direction="row">
                    <Divider orientation="vertical" />
                    <Text pb="10" as={Header} fontSize="xx-large">
                      Client's Details
                    </Text>
                  </Stack>

                  <FormControl
                    key={'Clients_name' + generate()}
                    mb={6}
                  >
                    <FormLabel color="gray.600">
                      Client's name
                    </FormLabel>
                    <Input
                      // isRequired
                      variant="filled"
                      {...register('submitterName')}
                      placeholder={"Client's name"}
                      name={'submitterName'}
                      size="lg"
                    />
                  </FormControl>
                  <FormControl
                    key={'Email_Address' + generate()}
                    mb={6}
                  >
                    <FormLabel color="gray.600">
                      Email Address
                    </FormLabel>
                    <Input
                      // isRequired
                      variant="filled"
                      {...register('submitterEmail')}
                      placeholder={'Email Address'}
                      name={'submitterEmail'}
                      size="lg"
                    />
                  </FormControl>
                  <FormControl
                    key={'Company_Organization' + generate()}
                    mb={6}
                  >
                    <FormLabel color="gray.600">
                      Company/Organization
                    </FormLabel>
                    <Input
                      // isRequired
                      variant="filled"
                      {...register('submitterOrg')}
                      placeholder={'Company_Organization'}
                      name={'submitterOrg'}
                      size="lg"
                    />
                  </FormControl>
                  <FormControl
                    key={'Contact_information' + generate()}
                    mb={6}
                  >
                    <FormLabel color="gray.600">
                      Contact Information - Company/Organization
                    </FormLabel>
                    <Input
                      // isRequired
                      variant="filled"
                      {...register('submitterContactInfo')}
                      placeholder={'Contact information'}
                      name={'submitterContactInfo'}
                      size="lg"
                    />
                  </FormControl>

                  <Stack direction="row">
                    <Divider orientation="vertical" />
                    <Text pb="10" as={Header} fontSize="xx-large">
                      Parties Information
                    </Text>
                  </Stack>

                  <FormControl
                    key={'Owner_Sellers_Name' + generate()}
                    mb={6}
                  >
                    <FormLabel color="gray.600">
                      Seller's Name
                    </FormLabel>
                    <Input
                      // isRequired
                      variant="filled"
                      {...register('sellerName')}
                      placeholder={"Seller's Name"}
                      name={'sellerName'}
                      size="lg"
                    />
                  </FormControl>
                  <FormControl
                    key={
                      'Owner_Sellers_Contact_Information' + generate()
                    }
                    mb={6}
                  >
                    <FormLabel color="gray.600">
                      Seller's Contact Information
                    </FormLabel>
                    <Input
                      // isRequired
                      variant="filled"
                      {...register('ownerContactInformation')}
                      placeholder={
                        "Seller's Contact Information"
                      }
                      name={'ownerContactInformation'}
                      size="lg"
                    />
                  </FormControl>
                  <FormControl
                    key={'Buyers_Name' + generate()}
                    mb={6}
                  >
                    <FormLabel color="gray.600">
                      Buyer's/Borrower's Name
                    </FormLabel>
                    <Input
                      isRequired
                      variant="filled"
                      {...register('buyerName')}
                      placeholder={"Buyer's/Borrower's Name"}
                      name={'buyerName'}
                      size="lg"
                    />
                  </FormControl>
                  <FormControl
                    key={'Buyers_Contact_Information' + generate()}
                    mb={6}
                  >
                    <FormLabel color="gray.600">
                      Buyer's/Borrower's Contact Information
                    </FormLabel>
                    <Input
                      // isRequired
                      variant="filled"
                      {...register('buyerContact')}
                      placeholder={"Buyer's/Borrower's Contact Information"}
                      name={'buyerContact'}
                      size="lg"
                    />
                  </FormControl>

                  <Stack direction="row">
                    <Divider orientation="vertical" />
                    <Text pb="10" as={Header} fontSize="xx-large">
                      Sale Details
                    </Text>
                  </Stack>

                  <FormControl key={'address' + generate()} mb={6}>
                    <FormLabel color="gray.600">
                      Address or Legal Description
                    </FormLabel>
                    <Input
                      // isRequired
                      variant="filled"
                      {...register('address')}
                      placeholder={'Address or Legal Description'}
                      name={'address'}
                      size="lg"
                    />
                  </FormControl>
                  <FormControl
                    key={'Loan_Policy_Endorsements' + generate()}
                    mb={6}
                  >
                    <FormLabel color="gray.600">
                      Loan Policy Endorsements
                    </FormLabel>
                    <Input
                      // isRequired
                      variant="filled"
                      {...register('loanEndorsement')}
                      placeholder={'Loan Policy Endorsements'}
                      name={'loanEndorsement'}
                      size="lg"
                    />
                  </FormControl>
                  <FormControl key={'Purpose' + generate()} mb={6}>
                    <FormLabel color="gray.600">
                      If applicable, Purpose of Loan
                    </FormLabel>
                    <Input
                      // isRequired
                      variant="filled"
                      {...register('loanPurpose')}
                      placeholder={'If applicable, Purpose of Loan'}
                      name={'loanPurpose'}
                      size="lg"
                    />
                  </FormControl>
                  <FormControl
                    key={'Order_Tax_Certificate' + generate()}
                    mb={6}
                  >
                    <FormLabel color="gray.600">
                      Order Tax Certificate
                    </FormLabel>
                    <Input
                      isRequired
                      variant="filled"
                      {...register('taxCertificate')}
                      placeholder={'Yes or No'}
                      name={'taxCertificate'}
                      size="lg"
                    />
                  </FormControl>
                  <FormControl
                    key={'Lender_Name' + generate()}
                    mb={6}
                  >
                    <FormLabel color="gray.600">
                      Lender Name
                    </FormLabel>
                    <Input
                      // isRequired
                      variant="filled"
                      {...register('lenderName')}
                      placeholder={'Lender Name'}
                      name={'lenderName'}
                      size="lg"
                    />
                  </FormControl>
                  <FormControl
                    key={'Loan_Officer_Name' + generate()}
                    mb={6}
                  >
                    <FormLabel color="gray.600">
                      Loan Officer Name
                    </FormLabel>
                    <Input
                      // isRequired
                      variant="filled"
                      {...register('loanOfficer')}
                      placeholder={'Loan Officer Name'}
                      name={'loanOfficer'}
                      size="lg"
                    />
                  </FormControl>
                  <FormControl
                    key={'Additional_Information' + generate()}
                    mb={6}
                  >
                    <FormLabel color="gray.600">
                      Additional Information
                    </FormLabel>
                    <Textarea
                      variant="filled"
                      {...register('extraInformation')}
                      placeholder={
                        'Please input any additional information here.'
                      }
                      name={'extraInformation'}
                      resize="none"
                      size="lg"
                    />
                  </FormControl>
                  <FormControl
                    key={'Upload_Documents' + generate()}
                    mb={6}
                  >
                    <FormLabel color="gray.600">
                      Upload Documents
                    </FormLabel>
                    <Input
                      type="file"
                      ref={register}
                      multiple
                      {...register('documentsUpload')}
                      name={'documentsUpload'}
                      size="lg"
                      onChange={handleChange}
                    />
                  </FormControl>
                  <Button
                    variant="btnOne"
                    mt={3}
                    onClick={handleSubmit(sendEmail)}
                  >
                    <EmailIcon />
                    Send
                  </Button>
                </Card>
              </form>
            </Box>
          </AnimatedBox>
        </div>
      </Container>
      <Hill2 />
    </Box>
  );
};

export default RequestForm;
