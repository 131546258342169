import { extendTheme } from '@chakra-ui/react';
import { Buttons, Links } from './Buttons-Links';
import { Headings, Fonts, Texts } from './Typography';

const ListHeader = {
  baseStyle: {},
  sizes: {},
  variants: {
    itemOne: {
      paddingRight: '1rem',
      paddingLeft: '1rem',
      position: 'relative',
      fontWeight: 600,
      letterSpacing: '.1rem',
      _after: {
        transition: 'all 0.15s ease-out',
        zIndex: '-1',
        bgGradient: 'linear(to-br, , brand.one 80%,  brand.three )',
        borderRadius: '10px',
        position: 'absolute',
        content: '""',
        height: '5px',
        width: '100%',
        paddingRight: '2rem',
        paddingLeft: '2rem',
        bottom: '-5px',
        left: '0%',
        color: 'red.100',
      },
    },
  },
};

const theme = extendTheme({
  textStyles: {
    lineHeight: '1.5',
  },
  components: {
    Input: {},
    Textarea: {},
    Box: {
      variants: {
        dark: {},
      },
    },
    Link: Links,
    Button: Buttons,
    Text: Texts,
    Heading: Headings,
    ListHeader,
  },
  fontSizes: {
    '10xl': '9rem',
  },
  fonts: Fonts,
  styles: {
    global: {
      body: {
        minHeight: '100vh',
      },
      a: {
        color: 'teal.500',
      },
    },
    overlay: {
      backgroundColor: 'rgba(0,0,0,0.9)',
    },
  },
  colors: {
    black: '#000000', // deep-black
    white: '#ffffff', // off-white, ?eggshell?, IDK ask a woman
    brand: {
      one: '#337C7E', // indigo
      two: '#1d4c4e', // gold-tan
      three: '#094446', // grey-tan
      four: '#E4D7C7', // tan-tan
      five: '#F2EBDB',
      six: '#E2DCD8',
    },
  },
});

export default theme;
