import {
  Box, Heading, Container, OrderedList, ListItem,
} from '@chakra-ui/react';
import EstimateHero from '../Subcomponents/EstimateHero/EstimateHero';
import DisplayPackagePricing from './Forms/DisplayPackagePricing';
import { Card } from '../../_index';
import { HomeHeroBGXl } from '../../../Assets/illustration/home-ill';

const Disclaimer = () => {
  return (
    <>
      <Heading as="h3" size="xl" variant="sec" className="readable">
        Please recognize that all real estate closing costs vary based on a number of
        possible factors:
      </Heading>
      <OrderedList>
        <ListItem fontSize="lg">
          Property Tax proration amounts based on amount of taxes
          assessed and the date of closing.
        </ListItem>
        <ListItem fontSize="lg">
          Amount of Title Insurance requested for the transactions.
        </ListItem>
        <ListItem fontSize="lg">Terms contained in each Real Estate Contract</ListItem>
        <ListItem fontSize="lg">Various requirements of Lenders making the loan.</ListItem>
        <ListItem fontSize="lg">The County Clerk's charges for Recording Documents.</ListItem>
        <ListItem fontSize="lg">Survey cost, if any.</ListItem>
        <ListItem fontSize="lg">
          Cost to meet curative requirements of the Title Policy
          Commitment necessary to obtain Title Policy.
        </ListItem>
      </OrderedList>
    </>
  );
};

const PackageCosts = () => {
  return (
    <Box
      position="relative"
      py={20}
      bgGradient="linear(to-b, #FBD786 1%, #FFEFBA 10%, #FFFFFF 40%)"
    >
      <Box position="relative" zIndex="0">
        <Container maxW="container.xl">

          <EstimateHero />

        </Container>
      </Box>
      <Box backgroundColor="white" position="relative" py={10}>
        <Container maxW="container.lg">
          <Card
            top="2%"
            padding={10}
            backgroundColor="brand.fixe"
            mx={{ base: 'auto' }}
          >
            <Disclaimer />
          </Card>
        </Container>
        <Container maxW="container.xl">
          <Card
            top="2%"
            padding={10}

            mx={{ base: 'auto' }}
          >
            <Heading as="h3" size="lg" variant="sec">
              Select a package to see the estimated cost (subject to the terms above):
            </Heading>
            <Heading as="h4" size="md" variant="sec">
            Please choose Seller or Buyer for the estimated cost
            </Heading>
            
            <DisplayPackagePricing />
          </Card>
        </Container>
      </Box>

      <Box
        pos="absolute"
        bottom="0"
        left="0"
        zIndex="-1"
        width="100vw"
        overflow="hidden"
      >
        <HomeHeroBGXl width="100vw" height="auto" />
      </Box>
    </Box>
  );
};

export default PackageCosts;
