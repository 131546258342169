import React from 'react';
import { Box, Heading, Text, Container } from '@chakra-ui/react';
import { AnimatedFadeUpBox, Card } from '../../_index';
import { ReactComponent as Hill2 } from '../../../Assets/illustration/hill-xl-2.svg';

const WelcomeSection = () => {
  return (
    <AnimatedFadeUpBox
      showOffset="300"
      minH="500px"
      backgroundColor="brand.six"
      position="relative"
      zIndex="0"
      py={20}
    >
      <Container maxW="container.lg">
        <Card
          backgroundColor="white"
          display="flex"
          justifyContent="center"
          flexWrap="wrap"
          borderRadius={50}
          py={10}
          px={6}
        >
          <Heading as="h3" size="xl" >
          Welcome to Cowboy Country Title, LLC
          </Heading>
          <Text fontSize="2xl" className="readable" align="center">
          Deep in the heart of historic Erath County, Texas is Cowboy Country Title, LLC.
          Cowboy’s team values their connection to the local community and is eager to
          provide its residents with an exceptional closing experience.
          </Text>
        </Card>
      </Container>

      <Box position="absolute" bottom="0" left="0" zIndex="-1">
        <Hill2 />
      </Box>
    </AnimatedFadeUpBox>
  );
};

export default WelcomeSection;
